import { dayjs } from '@/lib/utils';
import { ArrowDownTrayIcon, DocumentTextIcon } from '@heroicons/react/24/outline';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFdk } from '../fdk';
import { MenuItem } from './Editor/MenuItem';
import { SheetHeader } from './ui/sheet';

export function SideNavHistory() {
  const { t } = useTranslation('translation');
  const { getValues } = useFormContext();

  const assetID = getValues('chapter.historyAudio').join(',');
  const { data, isLoading } = useFdk(
    assetID
      ? {
          assetGroup: 'audio_files',
          action: 'assetList',
          options: { assetID },
        }
      : null,
  );

  if (!data && isLoading)
    return (
      <>
        <SheetHeader>
          <h5 className="text-xl">{t('chapter.history.history')}</h5>
        </SheetHeader>
        <div className="flex justify-center items-center absolute w-full h-full">
          <span className="loading loading-infinity loading-lg"></span>
        </div>
      </>
    );

  if (!data && !isLoading)
    return (
      <>
        <SheetHeader>
          <h5 className="text-xl">{t('chapter.history.history')}</h5>
        </SheetHeader>
        <div className="flex grow justify-center items-center w-full">
          <span>{t('chapter.history.noHistoryFound')}</span>
        </div>
      </>
    );

  return (
    <>
      <SheetHeader>
        <h5 className="text-xl">{t('chapter.history.history')}</h5>
      </SheetHeader>
      <ul className="menu p-0 gap-y-2">
        {data?.items?.map((file: any) => (
          <MenuItem
            key={file?.assetID}
            headline={file?.created ? dayjs(file?.created).format('DD.MM.YYYY HH:mm') : ''}
            iconLeft={<DocumentTextIcon className="w-6 h-6 text-white" />}
            iconRight={<ArrowDownTrayIcon className="w-6 h-6 text-primary" />}
            onRightButtonHref={file?.file?.url}
          />
        ))}
      </ul>
    </>
  );
}
