import { Input as ShadInput } from '@/components/ui/input';
import { negotiateAsset } from '@/hooks/useAsset';
import { cn } from '@/lib/utils';
import { DndContext, KeyboardSensor, PointerSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { ArrowDownTrayIcon, ArrowsUpDownIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { Fragment, ReactNode, forwardRef, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { Controller, UseFormRegisterReturn } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import SelectSearch from 'react-select-search';
import 'react-select-search/style.css';
import { usePlainFdk } from '../fdk';
import { classNames } from '../utils';
import './../components/react-tags.css';
import { Slider } from './ui/slider';
import { Switch } from './ui/switch';
import { Toggle } from './ui/toggle';

export const DndZone = ({ items, move, remove, insert, children }) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd({ active, over }) {
    const activeIndex = items.findIndex((i) => i.id === active.id);
    const overIndex = items.findIndex((i) => i.id === over.id);
    move(activeIndex, overIndex);
  }

  return (
    <>
      <div
        onClick={() => {
          insert(0, {
            title: {
              de_DE: '',
            },
            cover: '',
            artist: '',
            description: {
              de_DE: '',
            },
            audio_map: {
              de_DE: {
                id: '',
                type: 'chapter',
              },
            },
            chapters: [],
            audio_files: [],
          });
        }}
        className="border border-dashed cursor-pointer hover:bg-primary/20 border-primary text-primary/80 p-2 rounded my-3 mb-6 ml-7 text-center"
      >
        <PlusCircleIcon className="w-6 h-6 inline" /> Station hinzufügen
      </div>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((item, i) => (
            <Fragment key={item.fieldId ?? i}>
              <SortableItem id={item.fieldId} remove={() => remove(i)}>
                {children(i)}
              </SortableItem>
              <div
                onClick={() => {
                  insert(i + 1, {
                    title: {
                      de_DE: '',
                    },
                    cover: '',
                    artist: '',
                    description: {
                      de_DE: '',
                    },
                    chapter: null,
                    audio_file: null,
                  });
                }}
                className="border border-dashed cursor-pointer hover:bg-primary/20 border-primary text-primary/80 p-2 rounded my-3 mb-6 ml-7 text-center"
              >
                <PlusCircleIcon className="w-6 h-6 inline" /> Station hinzufügen
              </div>
            </Fragment>
          ))}
        </SortableContext>
      </DndContext>
    </>
  );
};

export const DndZoneSideMenu = ({
  items,
  setActiveSection,
  move,
  remove,
  children,
  onDragStart,
}: {
  items: any;
  setActiveSection: any;
  move: any;
  remove: any;
  children: any;
  onDragStart?: any;
}) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  function handleDragEnd({ active, over }) {
    const activeIndex = items.findIndex((i) => i.id === active.id);
    const overIndex = items.findIndex((i) => i.id === over?.id);
    move(activeIndex, overIndex);
    setActiveSection(active.id);
    if (onDragStart) {
      onDragStart(over.id);
    }
  }

  return (
    <>
      <DndContext
        modifiers={[restrictToVerticalAxis]}
        sensors={sensors}
        collisionDetection={closestCenter}
        autoScroll={false}
        onDragEnd={handleDragEnd}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((item, i) => (
            <SortableItemSideMenu
              onClick={() => {
                setActiveSection(item.id);
                if (onDragStart) {
                  onDragStart(item.id);
                }
              }}
              id={item.id}
              key={item.fieldId ?? i}
              remove={() => remove(i)}
            >
              {children(i, item)}
            </SortableItemSideMenu>
          ))}
        </SortableContext>
      </DndContext>
    </>
  );
};

export function SortableItem(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className="flex gap-2" {...attributes}>
      <div className="flex flex-col gap-3">
        <ArrowsUpDownIcon className="w-5 h-5 text-primary cursor-pointer" {...listeners} />
        <TrashIcon onClick={props.remove} className="w-5 h-5 text-primary cursor-pointer" />
      </div>

      {props.children}
    </div>
  );
}

export function SortableItemSideMenu(props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} onClick={props.onClick} className="flex gap-2" {...listeners} {...attributes}>
      {props.children}
    </div>
  );
}

export const FormError = ({ error }) => {
  return <small className="text-red-500 pt-2">{error}</small>;
};

export const InputWithOverlay = forwardRef<HTMLElement, { children?: ReactNode } & UseFormRegisterReturn<any>>(
  ({ name, children, ...props }, ref) => (
    <div className="relative mt-2 rounded-md shadow-sm">
      <Input
        {...props}
        ref={ref}
        name={name}
        //@ts-ignore
        style={{ colorScheme: 'dark' }}
        className="input input-bordered [&:user-invalid]:input-warning w-full"
      />
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">Min</div>
    </div>
  ),
);

export const Input = forwardRef<HTMLElement, { label: string; children?: ReactNode } & UseFormRegisterReturn<any>>(
  ({ name, label, children, ...props }, ref) => (
    <div className="form-control">
      <label className="label" htmlFor={name as string}>
        <span className="label-text flex items-center gap-3">
          {label} {children}
        </span>
      </label>

      <ShadInput
        // @ts-expect-error ref issue
        ref={ref}
        name={name}
        {...props}
        style={{ colorScheme: 'dark' }}
        className="input input-bordered [&:user-invalid]:input-warning "
      />
    </div>
  ),
);

export const Textarea = forwardRef<HTMLElement, { label: any; children?: ReactNode } & UseFormRegisterReturn<any>>(
  ({ name, label, children, ...props }, ref) => (
    <div className="form-control">
      <label className="label" htmlFor={name as string}>
        <span className="label-text flex items-center gap-3">
          {label} {children}
        </span>
      </label>
      <textarea
        rows={10}
        // @ts-expect-error ref issue
        ref={ref}
        name={name}
        {...props}
        className="textarea textarea-bordered [&:user-invalid]:input-warning "
      />
    </div>
  ),
);

export const Select = ({ label, items, placeholder, control, name }) => {
  return (
    <div className="form-control ">
      <label className="label">
        <span className="label-text flex items-center gap-3">{label}</span>
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <SelectSearch
            value={field.value}
            placeholder={placeholder}
            search
            fuzzySearch
            options={items.map((i) => ({ value: i.id, name: i.name, group: i.group }))}
            onChange={(value) => {
              field.onChange(value);
              return value;
            }}
            onBlur={field.onBlur}
            onFocus={() => null}
            renderValue={(valueProps) => <Input className="input input-bordered w-full" {...(valueProps as any)} />}
            emptyMessage="Keine Einträge gefunden"
          />
        )}
      />
    </div>
  );
};

export const DropZone = ({ value, onChange }) => {
  const [acceptedFiles, setAcceptedFiles] = useState<Record<string, any>>(value);
  const fdk = usePlainFdk();
  const { t } = useTranslation();

  useEffect(() => {
    if (value) {
      fdk
        .assetgroup('guide_tour_images')
        .getAsset(value?.assetID ?? value)
        .then(setAcceptedFiles);
    }
  }, [value]);

  const [pending, setPending] = useState(false);

  if (acceptedFiles) {
    return (
      <div className="relative overflow-hidden h-[250px] aspect-video rounded">
        <div
          className="absolute top-3  right-3 rounded flex items-center justify-center bg-white/80"
          onClick={() => {
            setAcceptedFiles(undefined as any);
            onChange(null);
          }}
        >
          <XMarkIcon className="w-6 h-6  text-red-500" />
        </div>
        {acceptedFiles.name}{' '}
        <img
          className="w-full h-full rounded object-cover aspect-video bg-white/50"
          draggable={false}
          src={negotiateAsset(acceptedFiles, 800)}
        />
      </div>
    );
  }

  return (
    <Dropzone
      accept={{
        'image/png': ['.png'],
        'image/jpeg': ['.jpeg', '.jpg'],
      }}
      onDrop={async (acceptedFiles) => {
        const assetGroup = fdk.assetGroup('guide_tour_images');
        setPending(true);
        try {
          const asset = await assetGroup.createAsset({
            file: acceptedFiles[0],
            options: {
              deduplicate: true,
            },
          } as any);
          onChange(asset);
          setAcceptedFiles(asset);
          toast.success('File uploaded');
        } catch (e) {
          toast.error(JSON.stringify(e));
          console.log(e);
        } finally {
          setPending(false);
        }
      }}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <section
          className={classNames(
            'border border-border h-[250px] border-dashed rounded p-4',
            isDragActive && 'bg-primary/10',
          )}
        >
          {pending ? (
            <div className="skeleton flex items-center justify-center w-full h-full">
              <span className="loading loading-ring loading-sm"></span>
            </div>
          ) : (
            <div {...getRootProps()} className="h-full w-full">
              <input {...getInputProps()} />
              <div className="flex gap-2 items-center h-full justify-center">
                <ArrowDownTrayIcon className="w-4 h-4" />{' '}
                {isDragActive ? <span>{t('imagePicker.isDragging')}</span> : <span>{t('imagePicker.isIdle')}</span>}{' '}
              </div>
              {acceptedFiles && <img src={(acceptedFiles as any)?.file?.url} />}
            </div>
          )}
        </section>
      )}
    </Dropzone>
  );
};

export const AudioDrop = ({ value, onChange }) => {
  const [acceptedFiles, setAcceptedFiles] = useState<Record<string, any>>();
  const { t } = useTranslation();
  const fdk = usePlainFdk();
  useEffect(() => {
    if (value) {
      fdk
        .assetgroup('guide_tour_audio')
        .getAsset(value.assetID || value)
        .then((res) => {
          setAcceptedFiles(res);
        });
    }
  }, [value]);

  const [pending, setPending] = useState(false);

  if (acceptedFiles) {
    return (
      <div className="relative border p-4 border-primary items-center overflow-hidden flex justify-between rounded">
        <span className=" text-lg ">{acceptedFiles.title} </span>
        <small>{acceptedFiles.file?.size / 1000} KB</small>
        <div
          className=" top-3  right-3 rounded flex items-center justify-center bg-white/80"
          onClick={() => {
            setAcceptedFiles(undefined);
            onChange(null);
          }}
        >
          <XMarkIcon className="w-6 h-6  text-red-500" />
        </div>
        {/* <img className="w-full h-full rounded object-cover bg-white/50" src={acceptedFiles?.file?.url} /> */}
      </div>
    );
  }

  return (
    <Dropzone
      accept={{
        'audio/*': ['.mp3'],
      }}
      onDrop={async (acceptedFiles) => {
        const assetGroup = fdk.assetGroup('guide_tour_audio');
        setPending(true);
        try {
          const asset = await assetGroup.createAsset({
            file: acceptedFiles[0],
            options: {
              deduplicate: true,
              preserveFilenames: true,
            },
          } as any);
          onChange(asset);
          setAcceptedFiles(asset);
          toast.success('Datei gespeichert');
        } catch (e) {
          toast.error(JSON.stringify(e));
          console.log(e);
        } finally {
          setPending(false);
        }
      }}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <section className={classNames('border h-[250px] border-dashed rounded p-4', isDragActive && 'bg-primary/10')}>
          {pending ? (
            <div className="skeleton flex items-center justify-center w-full h-full">
              <span className="loading loading-ring loading-sm"></span>
            </div>
          ) : (
            <div {...getRootProps()} className="h-full w-full">
              <input {...getInputProps()} />
              <p className="flex gap-2 items-center h-full justify-center">
                <ArrowDownTrayIcon className="w-4 h-4" />{' '}
                {isDragActive ? <span>{t('audioPicker.isDragging')}</span> : <span>{t('audioPicker.isIdle')}</span>}{' '}
              </p>
              {acceptedFiles && <img src={(acceptedFiles as any)?.file?.url} />}
            </div>
          )}
        </section>
      )}
    </Dropzone>
  );
};

export const SliderInput = ({ label, control, name, min, max, step = 0.1, unit = null }: any) => {
  return (
    <div className="form-control space-y-2">
      <label className="label">
        <span className="label-text flex items-center gap-1">{label}</span>
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <div className="flex justify-between items-center gap-x-4">
            <Slider
              onValueChange={(value) => {
                if (value.length) {
                  field.onChange(value[0] || 0);
                }
              }}
              min={min}
              max={max}
              step={step}
              value={[field.value]}
              name={name}
              className={cn('w-[100%]')}
            />
            <div className="w-8 text-sm">
              {field.value}
              {unit}
            </div>
          </div>
        )}
      />
    </div>
  );
};

export const ToggleInput = ({ label, control, name }) => {
  return (
    <div className="form-control space-y-2">
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <div className="flex justify-between items-center gap-x-4">
            <Toggle
              onPressedChange={(value) => {
                field.onChange(value);
              }}
              pressed={field.value}
              name={name}
              className={cn('w-[100%]')}
            >
              {label}
            </Toggle>
          </div>
        )}
      />
    </div>
  );
};

export const SwitchInput = ({ label, control, name }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <label className="form-control space-y-2 gap-x-4 flex">
          <Switch checked={field.value} onCheckedChange={field.onChange} disabled={field.disabled} />
          {label}
        </label>
      )}
    />
  );
};
