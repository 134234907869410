import { ProjectCreateSTT } from '@/routes/ProjectCreateSTT.tsx';
import { OidcSecure, useOidc } from '@axa-fr/react-oidc';
import { useEffect, lazy } from 'react';
import { Navigate, Outlet, RouterProvider, createBrowserRouter, useLocation } from 'react-router-dom';
import CreateStudio from './components/CreateStudio.tsx';
import CustomColors from './components/CustomColors.tsx';
import Intercom from './components/Intercom.tsx';
import { LimitAlert } from './components/LimitAlert.tsx';
import { SubscriptionCheck } from './components/SubscriptionCheck.tsx';
const AboManage = lazy(() => import('./routes/AboManage.tsx'));
const AboManageSuccess = lazy(() => import('./routes/AboManageSuccess.tsx'));
const AboSelect = lazy(() => import('./routes/AboSelect.tsx'));
const ChapterDetail = lazy(() => import('./routes/ChapterDetail.tsx'));
const EmailVerification = lazy(() => import('./routes/EmailVerification.tsx'));
const Onboarding = lazy(() => import('./routes/Onboarding.tsx'));
const ProjectCreate = lazy(() => import('./routes/ProjectCreate.tsx'));
const ProjectDetail = lazy(() => import('./routes/ProjectDetail.tsx'));
const Projects = lazy(() => import('./routes/Projects.tsx'));
const Register = lazy(() => import('./routes/Register.tsx'));
const Settings = lazy(() => import('./routes/Settings.tsx'));
const Studios = lazy(() => import('./routes/Studios.tsx'));
const Voices = lazy(() => import('./routes/Voices.tsx'));
const GuideApp = lazy(() => import('./routes/guide/GuideApp.tsx'));
const GuideCreateAndRedirect = lazy(() => import('./routes/guide/GuideCreateAndRedirect.tsx'));
const GuideDetail = lazy(() => import('./routes/guide/GuideDetail.tsx'));
const GuideList = lazy(() => import('./routes/guide/GuideList.tsx'));
const GuideSettings = lazy(() => import('./routes/guide/GuideSettings.tsx'));

function Callback() {
  const loc = useLocation();
  const state = new URLSearchParams(loc.search).get('state');
  if (state && state.startsWith('redirect:')) {
    // made up url format for redirects..
    // state is used also for other things so we have to prepend a special string..
    const [_, route] = state.split('redirect:');
    return <Navigate to={route} />;
  }
  return null;
}

function SignupCallback() {
  const loc = useLocation();
  const query = new URLSearchParams(loc.search);
  const invite = query.get('invite');
  const email = query.get('email');
  const { login } = useOidc();
  useEffect(() => {
    login(`/`, {
      extras: query.toString(),
      //prompt: 'create',
    });
  }, [invite, email]);
  return <p>Du wirst weitergeleitet...</p>;
}

const router = createBrowserRouter([
  {
    element: <Intercom />,
    children: [
      {
        path: '/',
        element: (
          <OidcSecure>
            <Studios />
          </OidcSecure>
        ),
      },
      {
        path: '/:shortID',
        element: (
          <OidcSecure>
            <SubscriptionCheck />
            <LimitAlert />
            <Outlet />
            <CustomColors />
          </OidcSecure>
        ),
        children: [
          { index: true, element: <Projects /> },
          {
            path: 'voices',
            element: <Voices />,
          },
          {
            path: 'projects',
            children: [
              { index: true, element: <Projects /> },
              { path: 'create', element: <ProjectCreate /> },
              { path: 'create-stt', element: <ProjectCreateSTT /> },
              {
                path: ':projectID',
                children: [
                  { index: true, element: <ProjectDetail /> },
                  {
                    path: 'create',
                    element: <ProjectCreate chapter />,
                  },
                ],
              },
              {
                path: ':projectID/chapters/:chapterID',
                element: <ChapterDetail />,
              },
            ],
          },
          {
            path: 'guide',
            children: [
              {
                index: true,
                element: <GuideCreateAndRedirect />,
              },
              {
                path: ':guideID',
                children: [
                  { index: true, element: <GuideList /> },
                  {
                    path: ':stationID',
                    element: <GuideDetail />,
                  },
                ],
              },
              {
                path: 'settings',
                children: [
                  {
                    path: ':guideID',
                    element: <GuideSettings />,
                  },
                ],
              },
              {
                path: 'app',
                children: [
                  {
                    path: ':guideID',
                    element: <GuideApp />,
                  },
                ],
              },
            ],
          },
          /* DEPRECATED
          {
            path: 'audio-guide',
            element: <AudioGuideList />,
          },
          {
            path: 'audio-guide/create',
            element: <AudioGuideCreate />,
          },
          {
            path: 'audio-guide/:tourID',
            element: <AudioGuideDetail />,
          },
          */
          {
            path: 'settings',
            children: [
              { index: true, element: <Settings /> },
              { path: 'abo-manage', element: <AboManage /> },
              { path: 'abo-manage/success/:checkoutSessionID', element: <AboManageSuccess /> },
            ],
          },
        ],
      },
      {
        path: '/onboarding',
        element: (
          <OidcSecure>
            <Onboarding />
          </OidcSecure>
        ),
      },
      {
        path: '/onboarding/aboselect',
        element: (
          <OidcSecure>
            <AboSelect />
          </OidcSecure>
        ),
      },
      {
        path: '/onboarding/:checkoutSessionID',
        element: (
          <OidcSecure>
            <CreateStudio />
          </OidcSecure>
        ),
      },
      {
        path: '/auth/email-verification/:jwt',
        element: <EmailVerification />,
      },
      {
        path: '/auth/email-verification/abort/:jwt',
        element: <EmailVerification abort={true} />,
      },
      {
        path: 'authentication',
        children: [
          {
            // auth would work without this route, but we need to fetch the state for redirects
            path: 'callback',
            element: <Callback />,
          },
        ],
      },
      {
        path: 'register',
        element: <Register />,
      },
      {
        path: 'signup',
        element: <SignupCallback />,
      },
    ],
  },
]);

export function Router() {
  return <RouterProvider router={router} />;
}
