import { usePlainFdk } from '@/fdk';
import { fetchEventSource } from '@microsoft/fetch-event-source';
import { useContext, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TrackContext } from '../../routes/ChapterDetail';
import { showModal } from '../Modal';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../ui/dialog';
import { Label } from '../ui/label';
import { Progress } from '../ui/progress';

export default function ChapterGenerateModal({ mutate }) {
  const { t } = useTranslation('translation');
  const [pending, setPending] = useState(false);
  const [progress, setProgress] = useState(0);
  const { setGeneratingError, setShowGenerate } = useContext(TrackContext);
  const { shortID, chapterID } = useParams();
  const fdk = usePlainFdk();

  const { watch } = useFormContext();

  useEffect(() => {
    fetchEventSource(`${import.meta.env.VITE_QUEUE_URL}/${shortID}/addJob/${chapterID}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      async onopen() {
        setProgress(0);
        setPending(true);
      },
      async onmessage({ data, event }) {
        if (event === 'progress') {
          const { percentage } = JSON.parse(data);
          setProgress(percentage);
        }
        if (event === 'completed') {
          toast.success('Erfolgreich generiert');
          setShowGenerate(false);
          await mutate();
        }
        if (event === 'failed') {
          await fdk.model('chapter').editEntry(chapterID as string, { state: 'errored' });
          toast.error('Fehler beim Generieren');
          setGeneratingError(data);
        }
      },
      async onclose() {
        setPending(false);
      },
      onerror() {
        showModal('generatingFailedModal');
        setShowGenerate(false);
        throw new Error('no_retry');
      },
      openWhenHidden: true,
    }).catch(() => null);
  }, [chapterID]);

  return (
    <Dialog open={watch('chapter.state') === 'generating'}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('chapter.chapterGeneratingModal.headline')}</DialogTitle>
        </DialogHeader>

        <div className="flex flex-col">
          <div>
            <Label>{t('chapter.chapterGeneratingModal.description')}</Label>
          </div>
          {pending && (
            <div className="w-full py-2 px-4 mt-4">
              <Progress className="progress w-full" value={progress} />
              <span className="text-center block text-xs mt-1">
                {progress <= 90
                  ? t('chapter.chapterGeneratingModal.pendingMessage')
                  : t('chapter.chapterGeneratingModal.pendingSoonFinishedMessage')}
              </span>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
