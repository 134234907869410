import {
  BookOpenIcon,
  BuildingLibraryIcon,
  ClipboardDocumentCheckIcon,
  DocumentIcon,
  RssIcon,
} from '@heroicons/react/24/outline';
import { ChatBubbleBottomCenterTextIcon } from '@heroicons/react/24/solid';

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

declare const window: any;

export function googleTagManagerEvent(eventName, additionalData?) {
  window?.dataLayer?.push({ event: eventName, ...additionalData });
}

export function getFlagEmoji(countryCode) {
  if (!countryCode) {
    return null;
  }

  const countryCodeByLanguage = languages.en.find((e) => e.language === countryCode);

  const codePoints = (
    countryCodeByLanguage?.countryCode ??
    countryCodeByLanguage?.language ??
    countryCode?.split('-').pop()
  )
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
}

export const TAGS = {
  de: {
    gender: 'Geschlecht',
    tone: 'Stimmfarbe',
    topic: 'Stil',
    character: 'Charakter',
  },
  en: {
    gender: 'Gender',
    tone: 'Voice color',
    topic: 'Style',
    character: 'Character',
  },
};

export const languages = {
  de: [
    {
      language: 'BG',
      name: 'Bulgarisch',
    },
    {
      language: 'CS',
      name: 'Tschechisch',
      countryCode: 'CZ',
    },
    {
      language: 'DA',
      name: 'Dänisch',
      countryCode: 'DK',
    },
    {
      language: 'de_DE',
      countryCode: 'DE',
      name: 'Deutsch',
    },
    {
      language: 'de_SIMPLE',
      countryCode: 'DE',
      name: 'Deutsch Leichte Sprache',
    },
    {
      language: 'de_KIDS',
      countryCode: 'DE',
      name: 'Deutsch kindgerecht',
    },
    {
      language: 'EL',
      name: 'Griechisch',
      countryCode: 'GR',
    },
    {
      language: 'EN-GB',
      name: 'English (Britisch)',
      countryCode: 'GB',
    },
    {
      language: 'EN-US',
      name: 'English (Amerikanisch)',
      countryCode: 'US',
    },
    {
      language: 'ES',
      name: 'Spanisch',
    },
    {
      language: 'ET',
      name: 'Estnisch',
    },
    {
      language: 'FI',
      name: 'Finnisch',
    },
    {
      language: 'FR',
      name: 'Französisch',
    },
    {
      language: 'HU',
      name: 'Ungarisch',
    },
    {
      language: 'ID',
      name: 'Indonesisch',
    },
    {
      language: 'IT',
      name: 'Italienisch',
    },
    {
      language: 'JA',
      name: 'Japanisch',
      countryCode: 'JP',
    },
    {
      language: 'KO',
      name: 'Koreanisch',
      countryCode: 'KR',
    },
    {
      language: 'LT',
      name: 'Litauisch',
    },
    {
      language: 'LV',
      name: 'Lettisch',
    },
    {
      language: 'NB',
      name: 'Norwegisch',
      countryCode: 'NOR',
    },
    {
      language: 'NL',
      name: 'Niederländisch',
    },
    {
      language: 'PL',
      name: 'Polnisch',
    },
    {
      language: 'PT-BR',
      name: 'Portugiesisch (Brasilianisch)',
      countryCode: 'BR',
    },
    {
      language: 'PT-PT',
      name: 'Portugiesisch (Europäisch)',
      countryCode: 'PT',
    },
    {
      language: 'RO',
      name: 'Rumänisch',
    },
    {
      language: 'RU',
      name: 'Russisch',
    },
    {
      language: 'SK',
      name: 'Slowakisch',
    },
    {
      language: 'SL',
      name: 'Slowenisch',
    },
    {
      language: 'SV',
      name: 'Schwedisch',
      countryCode: 'SE',
    },
    {
      language: 'TR',
      name: 'Türkisch',
    },
    {
      language: 'UK',
      name: 'Ukrainisch',
      countryCode: 'UA',
    },
    {
      language: 'ZH',
      name: 'Chinesisch',
      countryCode: 'CN',
    },
  ].sort((a, b) => a.name.localeCompare(b.name)),
  en: [
    {
      language: 'BG',
      name: 'Bulgarian',
    },
    {
      language: 'CS',
      name: 'Czech',
      countryCode: 'CZ',
    },
    {
      language: 'DA',
      name: 'Danish',
      countryCode: 'DK',
    },
    {
      language: 'de_DE',
      countryCode: 'DE',
      name: 'German',
    },
    {
      language: 'de_SIMPLE',
      countryCode: 'DE',
      name: 'German simple language',
    },
    {
      language: 'de_KIDS',
      countryCode: 'DE',
      name: 'German child friendly',
    },
    {
      language: 'EL',
      name: 'Greek',
      countryCode: 'GR',
    },
    {
      language: 'EN-GB',
      name: 'English (British)',
      countryCode: 'GB',
    },
    {
      language: 'EN-US',
      name: 'English (American)',
      countryCode: 'US',
    },
    {
      language: 'ES',
      name: 'Spanish',
    },
    {
      language: 'ET',
      name: 'Estonian',
    },
    {
      language: 'FI',
      name: 'Finnish',
    },
    {
      language: 'FR',
      name: 'French',
    },
    {
      language: 'HU',
      name: 'Hungarian',
    },
    {
      language: 'ID',
      name: 'Indonesian',
    },
    {
      language: 'IT',
      name: 'Italian',
    },
    {
      language: 'JA',
      name: 'Japanese',
      countryCode: 'JP',
    },
    {
      language: 'KO',
      name: 'Korean',
      countryCode: 'KR',
    },
    {
      language: 'LT',
      name: 'Litauisch',
    },
    {
      language: 'LV',
      name: 'Latvian',
    },
    {
      language: 'NB',
      name: 'Norwegian',
      countryCode: 'NOR',
    },
    {
      language: 'NL',
      name: 'Dutch',
    },
    {
      language: 'PL',
      name: 'Polish',
    },
    {
      language: 'PT-BR',
      name: 'Portuguese (Brazilian)',
      countryCode: 'BR',
    },
    {
      language: 'PT-PT',
      name: 'Portuguese (European)',
      countryCode: 'PT',
    },
    {
      language: 'RO',
      name: 'Romanian',
    },
    {
      language: 'RU',
      name: 'Russian',
    },
    {
      language: 'SK',
      name: 'Slovak',
    },
    {
      language: 'SL',
      name: 'Slovenian',
    },
    {
      language: 'SV',
      name: 'Swedish',
      countryCode: 'SE',
    },
    {
      language: 'TR',
      name: 'Turkish',
    },
    {
      language: 'UK',
      name: 'Ukrainian',
      countryCode: 'UA',
    },
    {
      language: 'ZH',
      name: 'Chinese',
      countryCode: 'CN',
    },
  ].sort((a, b) => a.name.localeCompare(b.name)),
};

export const assistants = {
  de: [
    {
      type: 'podcast',
      title: 'Podcast',
      icon: RssIcon,
      description: 'Erstelle einen Podcast mit mehreren Eposoden.',
      button: 'Vorschau erstellen',
      elements: [
        [
          {
            type: 'h1',
            content: 'Um deinen Podcast zu erstellen, benötige ich ein paar Informationen.',
          },
          {
            key: 'episodes',
            type: 'select',
            label: 'Mit wie vielen Episoden möchtest du starten?',
            required: 'Pflichtfeld',
            info: null,
            options: [
              { value: 1, label: 'eine Episode' },
              { value: 2, label: 'zwei Episoden' },
              { value: 3, label: 'drei Episoden' },
              { value: 4, label: 'vier Episoden' },
              { value: 5, label: 'fünf Episoden' },
              { value: 6, label: 'sechs Episoden' },
              { value: 7, label: 'sieben Episoden' },
              { value: 8, label: 'acht Episoden' },
              { value: 9, label: 'neun Episoden' },
              { value: 10, label: 'zehn Episoden' },
            ],
          },
        ],
        [
          {
            type: 'h1',
            content: 'Inhalt deines Podcasts',
          },
          {
            key: 'subject',
            type: 'input',
            label: 'Thema',
            required: 'Pflichtfeld',
            info: null,
            description: 'Beschreibe den Inhalt deines Podcasts in wenigen Worten',
            placeholder: 'zB "Ein Podcast über künstliche Intelligenz"',
          },
        ],
        [
          {
            key: 'host',
            type: 'input',
            label: 'Name des Host',
            required: 'Pflichtfeld',
            info: 'Aktuell kann lizzen Studio nur Podcasts mit einem Host generieren. Wir arbeiten bereits an Podcasts mit Dialogen.',
            description: 'Wer kommt in dem Podcast vor',
            placeholder: 'zB "Marie" oder "Markus"',
          },
        ],
        [
          {
            key: 'bio',
            type: 'textarea',
            label: 'Erzähle etwas über den Host',
            required: 'Pflichtfeld',
            info: null,
            description: null,
            placeholder:
              'zB "Markus ist Grafik-Designer aus München und liebt es, UX-Designs zu entwerfen. In seiner Freizeit fährt er gerne Fahrrad. Marie..."',
          },
        ],
        [
          {
            key: 'content',
            type: 'textarea',
            label: 'Inhalts-Details',
            required: false,
            info: null,
            description: 'Gehe darauf ein, welche Inhalte behandelt werden sollen',
            placeholder:
              'zB "Das Thema KI sollte mit praktischen Beispielen aus der Arbeitswelt den Hörer:innen näher gebracht werden."',
          },
        ],
        [
          {
            key: 'avoid',
            type: 'textarea',
            label: 'Inhalte vermeiden',
            required: false,
            info: null,
            description: 'Erkläre, welche Inhalte vermieden werden sollen',
            placeholder: 'zB "Bitte keine zu technischen Details."',
          },
        ],
        [
          {
            key: 'structure',
            type: 'textarea',
            label: 'Aufbau',
            required: false,
            info: null,
            description: 'Erkläre den Aufbau des Podcasts',
            placeholder:
              'zB "In jeder Episode sollte ein anderes Beispiel aus der Arbeitswelt behandelt werden. Die Episoden sollten am Ende einen Übergang / Cliffhanger zur nächsten Episode beinhalten."',
          },
        ],
        [
          {
            key: 'style',
            type: 'textarea',
            label: 'Ton und Stil',
            required: false,
            info: null,
            description: 'Gehe näher darauf ein, wie der Stil und Ton des Podcasts sein soll',
            placeholder:
              'zB "Locker und witzig, ab und zu etwas ironisch" oder "Sachlich und ernst, auf Fakten basierend" oder "unterhaltsam und frech"',
          },
        ],
      ],
      disabled: false,
    },
    false || {
      type: 'guide',
      title: 'Audioguide',
      icon: BuildingLibraryIcon,
      description: 'Mit wenigen Klicks zum Audioguide.',
      button: 'Erstellen',
      disabled: true,
    },
    false || {
      type: 'book',
      title: 'Hörbuch',
      icon: BookOpenIcon,
      description: 'Kreiere dein eigenes Hörbuch.',
      button: 'Erstellen',
      disabled: true,
    },
    {
      type: 'document',
      title: 'Dokument',
      icon: ClipboardDocumentCheckIcon,
      description: 'Benutze ein vorhandenes Dokument (pdf, docx).',
      button: 'Erstellen',
      elements: [
        [
          {
            type: 'h1',
            content: 'Wähle ein Dokument aus.',
          },
          {
            key: 'document',
            type: 'file',
            accept: ['.pdf', '.docx'],
            label: 'Dokument',
            required: 'Pflichtfeld',
            info: null,
            description: 'Es werden Dokumente in den Formaten .pdf und .docx untersützt',
          },
        ],
      ],
      disabled: false,
      chapter: true,
    },
    {
      type: 'stt',
      title: 'Audio Transkribieren',
      icon: ChatBubbleBottomCenterTextIcon,
      description: 'Lade eine Audio-Datei hoch und wandle diese in Text um.',
    },
    {
      type: 'empty',
      title: 'Ohne Vorlage',
      icon: DocumentIcon,
      description: 'Starte mit einem leeren Blatt Papier.',
      button: 'Erstellen',
      chapter: true,
    },
  ].filter(Boolean),
  en: [
    {
      type: 'podcast',
      title: 'Podcast',
      icon: RssIcon,
      description: 'Create a podcast with multiple episodes.',
      button: 'Create Preview',
      elements: [
        [
          {
            type: 'h1',
            content: 'To create your podcast, I need some information.',
          },
          {
            key: 'episodes',
            type: 'select',
            label: 'How many episodes do you want to start with?',
            required: 'Required',
            info: null,
            options: [
              {
                value: 1,
                label: 'an episode',
              },
              {
                value: 2,
                label: 'two episodes',
              },
              {
                value: 3,
                label: 'three episodes',
              },
              {
                value: 4,
                label: 'four episodes',
              },
              {
                value: 5,
                label: 'five episodes',
              },
              {
                value: 6,
                label: 'six episodes',
              },
              {
                value: 7,
                label: 'seven episodes',
              },
              {
                value: 8,
                label: 'eight episodes',
              },
              {
                value: 9,
                label: 'nine episodes',
              },
              {
                value: 10,
                label: 'ten episodes',
              },
            ],
          },
        ],
        [
          {
            type: 'h1',
            content: 'Content of your podcast',
          },
          {
            key: 'subject',
            type: 'input',
            label: 'Theme',
            required: 'Required',
            info: null,
            description: 'Describe the content of your podcast in a few words',
            placeholder: 'e.g. A podcast about artificial intelligence',
          },
        ],
        [
          {
            key: 'host',
            type: 'input',
            label: 'Host name',
            required: 'Required',
            info: 'Currently, lizzen Studio can only generate podcasts with one host. ',
            description: 'Who appears in the podcast?',
            placeholder: 'e.g. Marie or Markus',
          },
        ],
        [
          {
            key: 'bio',
            type: 'textarea',
            label: 'Tell us something about the host',
            required: 'Required',
            info: null,
            description: null,
            placeholder:
              'e.g. Markus is a graphic designer from Munich and loves creating UX designs. In his free time he likes to ride his bike. Marie...',
          },
        ],
        [
          {
            key: 'content',
            type: 'textarea',
            label: 'Content Details',
            required: false,
            info: null,
            description: 'Determine what content should be covered',
            placeholder:
              'e.g. The topic of AI should be brought closer to the listener using practical examples from the world of work.',
          },
        ],
        [
          {
            key: 'avoid',
            type: 'textarea',
            label: 'Avoid content',
            required: false,
            info: null,
            description: 'Explain what content should be avoided',
            placeholder: 'e.g. No technical details please.',
          },
        ],
        [
          {
            key: 'structure',
            type: 'textarea',
            label: 'Construction',
            required: false,
            info: null,
            description: 'Explain the structure of the podcast',
            placeholder:
              'e.g. Each episode should cover a different example from the world of work. The episodes should contain a transition/cliffhanger to the next episode at the end.',
          },
        ],
        [
          {
            key: 'style',
            type: 'textarea',
            label: 'tone and style',
            required: false,
            info: null,
            description: 'Go into more detail about what the style and tone of the podcast should be',
            placeholder:
              'e.g. loose and funny, a bit ironic now and then or factual and serious, based on facts or entertaining and cheeky',
          },
        ],
      ],
      disabled: false,
    },
    false || {
      type: 'guide',
      title: 'Audioguide',
      icon: BuildingLibraryIcon,
      description: 'Get the audioguide in just a few clicks.',
      button: 'Create',
      disabled: true,
    },
    false || {
      type: 'book',
      title: 'Audiobook',
      icon: BookOpenIcon,
      description: 'Create your own audio book.',
      button: 'Create',
      disabled: true,
    },
    {
      type: 'document',
      title: 'Document',
      icon: ClipboardDocumentCheckIcon,
      description: 'Use an existing document (pdf, docx).',
      button: 'Create',
      disabled: false,
      chapter: true,
    },
    {
      type: 'stt',
      title: 'Transcribe Audio',
      icon: ChatBubbleBottomCenterTextIcon,
      description: 'Upload an audio file and convert it to text.',
    },
    {
      type: 'empty',
      title: 'Without template',
      icon: DocumentIcon,
      description: 'Start with an empty sheet of paper.',
      button: 'Create',
      chapter: true,
    },
  ].filter(Boolean),
};
